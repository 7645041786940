import React, { useEffect } from 'react'
import defaultUserImg from '../../assets/images/user.jpg'
import '../top-section/topSection.css'

const Playercard = ({ activeTeamCard, auctionPlayer, activeRound }) => {

    return (
        <>
            {activeTeamCard && (
                <div className='col-sm-6'>
                    <div className='jumbotron jumbotron-custom' 
                        style={{ 
                            // backgroundColor: '#3e6fa9', 
                            background: `linear-gradient(90deg, #3e6fa7, #2f3358)`,
                            borderRadius: '20px', border: '1px solid #898989' 
                        }}>
                        <div className='row align-items-center'>
                            {/* Image on the left on larger screens */}
                            <div className='col-md-12 col-lg-4 d-flex justify-content-center justify-content-md-end'>
                                <div className='player'>
                                    <img
                                        src={activeTeamCard.Photo ? activeTeamCard.Photo : defaultUserImg}
                                        className='img-responsive img-circle img-user'
                                        style={{ border: '3px solid #4169E1' }}
                                        alt="Player"
                                    />
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-8 text-md-start'>
                                {activeTeamCard.PlayerID != 0 ? (
                                    <>
                                        <h2 className={`display-4 color-white`}>{activeTeamCard.PlayerName}</h2>
                                        <h4 className="fs-2 color-white">HCP: {activeTeamCard.Handicap}<span className='margin-l20'>{activeTeamCard.CategoryName}</span><span className={`kt-badge kt-badge--${activeTeamCard.CategoryName == 'Gold' ? 'gold' : activeTeamCard.CategoryName == 'Silver' ? 'silver' :'bronze'} kt-badge--inline kt-badge--pill kt-badge-pill-width margin-r50` }>
                                    {activeTeamCard.CategoryName}
                                </span></h4>
                                        <h4 className="fs-2 color-white">{activeTeamCard.MemberShipNo}</h4>
                                    </>
                                ) : (
                                    <h2 className="fs-2 color-white center-text margin-t0">TBA</h2>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* {activeRound.Round == 8 && ( */}
                {auctionPlayer ? (
                    <div className='col-sm-6'>
                        <div className='jumbotron jumbotron-custom' 
                            style={{ 
                                background: `linear-gradient(90deg, #0b79bf, #063554)`,
                                borderRadius: '20px', border: '1px solid #898989' 
                            }}>
                            <div className='row align-items-center'>
                                {/* Image on the left on larger screens */}
                                <div className='col-md-12 col-lg-4 d-flex justify-content-center justify-content-md-end'>
                                    <div className='player'>
                                        <img
                                            src={auctionPlayer.Photo ? auctionPlayer.Photo : defaultUserImg}
                                            className='img-responsive img-circle img-user'
                                            style={{ border: '3px solid #4169E1' }}
                                            alt="Player"
                                        />
                                    </div>
                                </div>
                                <div className='col-md-12 col-lg-8 text-lg-start'>
                                    {auctionPlayer.PlayerID != 0 ? (
                                        <>
                                            <h2 className={`display-4 color-white`}>{auctionPlayer.DisplayName}</h2>
                                            <h4 className="fs-2 color-white">HCP: {auctionPlayer.Handicap < 0 ? `+ ${Math.abs(auctionPlayer.Handicap)}` : auctionPlayer.Handicap}<span className={`kt-badge kt-badge--${auctionPlayer.CategoryName == 'Gold' ? 'gold' : auctionPlayer.CategoryName == 'Silver' ? 'silver' :'bronze'} kt-badge--inline kt-badge--pill kt-badge-pill-width margin-l50` }>
                                    {auctionPlayer.CategoryName}
                                </span></h4>
                                            <h4 className="fs-2 color-white">{auctionPlayer.MemberShipNo}</h4>
                                            <h4 className="fs-2 color-white">Gender: {auctionPlayer.Gender}</h4>
                                        </>
                                    ) : (
                                        <h2 className="fs-2 color-white center-text margin-t0">TBA</h2>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='col-md-6'>
                        <div className='jumbotron jumbotron-custom' 
                            style={{ 
                                // backgroundColor: '#3e6fa9', 
                                background: `linear-gradient(90deg, #0b79bf, #063554)`,
                                borderRadius: '20px', border: '1px solid #898989' 
                            }}>
                            <div className='row align-items-center'>
                                {/* Image on the left on larger screens */}
                                <div className='col-md-12 col-lg-4 d-flex justify-content-center justify-content-md-end'>
                                    <div className='player'>
                                        <img
                                            src={defaultUserImg}
                                            className='img-responsive img-circle img-user'
                                            style={{ border: '3px solid #4169E1' }}
                                            alt="Player"
                                        />
                                    </div>
                                </div>
                                <div className='col-md-12 col-lg-8 text-md-start'>
                                    <h2 className="fs-2 color-white center-text margin-t0">TBA</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            {/* )} */}
        </>
    )
}

export default Playercard