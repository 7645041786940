import { useEffect, useRef, useState } from "react";
import defaultTeamImg from '../../assets/images/defaultlogo.jpg'
const AllTeam = ({ teamList }) => {
    const scrollRef = useRef(null);
    const scrollSpeed = 1.5; // Adjust scrolling speed
    const duplicateTeamList = [...teamList, ...teamList]; // Duplicate the list for seamless scrolling

    useEffect(() => {
        const scrollContainer = scrollRef.current;

        const scrollStep = () => {
            if (scrollContainer) {
                scrollContainer.scrollLeft += scrollSpeed;

                // If we've scrolled past the original content, reset seamlessly
                if (
                    scrollContainer.scrollLeft >=
                    (scrollContainer.scrollWidth / 2)
                ) {
                    scrollContainer.scrollLeft = 0; // Reset to the start of the duplicated list
                }
            }
            requestAnimationFrame(scrollStep);
        };

        const animationId = requestAnimationFrame(scrollStep);

        return () => cancelAnimationFrame(animationId); // Cleanup on unmount
    }, [scrollSpeed]);

    return (
        <div className="team-section row margin-t30 margin-b60" style={{ color: "white" }}>
            <div
                className="scrollable-team-container"
                ref={scrollRef}
                style={{
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    padding: "10px 0",
                }}
            >
                {duplicateTeamList.map((t, i) => (
                    <div
                        className="team-card"
                        key={`${t.ID}-${i}`} // Ensure unique key
                        style={{
                            display: "inline-block",
                            marginLeft: "25px",
                        }}
                    >
                        <div
                            className="jumbotron"
                            style={{
                                background: `linear-gradient(180deg, ${t.LowColor}, ${t.HighColor})`,
                                padding: "20px 0px 30px 0px",
                                borderRadius: "20px",
                                width: "200px",
                            }}
                        >
                            <div className="player">
                                <img
                                    src={t.Logo || defaultTeamImg}
                                    className="img-responsive img-circle img-team"
                                    style={{
                                        border: "3px solid #4169E1",
                                        maxWidth: "100px",
                                        maxHeight: "100px",
                                    }}
                                />
                            </div>
                            <div className="text-center margin-t5" style={{ fontSize: "160%" }}>
                                {t.DisplayName}
                            </div>
                            <div className="text-center margin-t10" style={{ fontSize: "85%" }}>
                                Fund Remaining
                            </div>
                            <div className="text-center" style={{ fontSize: "185%", lineHeight: "20px" }}>
                                Rs. {t.RemainingAmount}
                            </div>
                            <div
                                className="card-footer margin-t20"
                                style={{ display: "flex", justifyContent: "space-around" }}
                            >
                                <div
                                    className="confirm"
                                    style={{
                                        lineHeight: "20px",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div className="text-center" style={{ fontSize: "90%" }}>
                                        Confirmed
                                    </div>
                                    <div className="text-center" style={{ fontSize: "130%" }}>
                                        {t.PlayerCount}
                                    </div>
                                    <div className="text-center" style={{ lineHeight: "5px", fontSize: "75%" }}>
                                        Players
                                    </div>
                                </div>
                                <div
                                    className="pending"
                                    style={{
                                        lineHeight: "20px",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div className="text-center" style={{ fontSize: "90%" }}>
                                        Pending
                                    </div>
                                    <div className="text-center" style={{ fontSize: "130%" }}>
                                        {15 - t.PlayerCount}
                                    </div>
                                    <div className="text-center" style={{ lineHeight: "5px", fontSize: "75%" }}>
                                        Players
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AllTeam;