import React, { useEffect, useState } from 'react'
import TeamSection from '../components/team-section/TeamSection'
import { io } from 'socket.io-client'
import { getActiveIsLive, getAuctionTeamByPlayerID, getDraftTeamByRound, getInProcessPlayer, getLastProcessedPlayer, getTeam } from '../components/api/teamAPI'
import NoStreaming from '../components/no-streaming/NoStreaming'
import swal from 'sweetalert2'
import AllTeam from '../components/team-section/AllTeam'
import TopSection from '../components/top-section/TopSection'
import PullToRefresh from 'react-simple-pull-to-refresh'
import AllTeamWithCarousal from '../components/team-section/AllTeamWithCarousal'
import NoStreamTeam from '../components/team-section/NoStreamTeam'
import BottomSection from '../components/BottomSection/bottomSection'
import bannerLogo from '../assets/images/PGL_logo.png';

const App = () => {

    const [activeRound,         setActiveRound]         = useState(null);
    const [teamList,            setTeamList]            = useState([]);
    const [activeTeamCard,      setActiveTeamCard]      = useState()
    const [auctionPlayer,       setAuctionPlayer]       = useState(null)
    const [auctionTeam,         setAuctionTeam]         = useState(null);

    const path = window.location.hostname.includes('localhost') ? 'http://localhost:8080' : `${window.location.protocol}//pgl-auction-admin.golfez.in`;

    useEffect(() => {
        initSocketConnection()
        fetchActiveIsLive()
    }, [])

    useEffect(() => {
        if (activeRound) {
            // if (activeRound.Round == 8) {
                setActiveTeamCard()
                fetchAllTeam()
                fetchInProcessPlayer()
            // } else {
            //     fetchTeam(activeRound.Round)
            // }

        }
    }, [activeRound])

    const initSocketConnection = () => {
        try {
            const socket = io(path, { transports: ['websocket'], withCredentails: false })

            socket.on('connect', () => socket.emit('user-connected', { socketID: socket.id }))

            socket.on('refresh-isLive', () => {
                fetchActiveIsLive()
            })
            socket.on('refresh-auctionPlayer', () => {
                fetchInProcessPlayer()
            })
            // socket.on('refresh-draftPlayer', (payload) => {
            //     if (payload.activeRound != 8)
            //         fetchTeam(payload.round)
            // })
            socket.on('refresh-auctionTeam', (payload) => {
                // if (payload.activeRound == 8) {
                    if (payload.from == 'unsold') {
                        setAuctionTeam({ TeamID: 0 })
                    } else {
                        fetchAuctionTeamByPlayerID(payload.playerID)
                        fetchAllTeam()
                    }
                // }
            })
        } catch (err) {                    
            swal.fire({ icon: 'error', buttonsStyling: false, confirmButtonClass: 'btn btn-brand',
                text: 'Please Reload...', title: "Something went wrong..!",
            }); 
        }  
    }

    const fetchActiveIsLive = async() => {
        try {
            const response = await getActiveIsLive()
            setActiveRound(response)            
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchInProcessPlayer= async() => {
        try {
            const response = await getInProcessPlayer()

            if (response) {
                setAuctionTeam(null)
                setAuctionPlayer(response)            
            } else {
                const lastProcessedPlayer = await getLastProcessedPlayer()
                if (lastProcessedPlayer.TeamID) {
                    setAuctionTeam(lastProcessedPlayer)
                } else if (lastProcessedPlayer.TeamID == 0) {
                    setAuctionTeam(lastProcessedPlayer)
                } else {
                    setAuctionTeam(null)
                }
                setAuctionPlayer(lastProcessedPlayer)
            }
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchAllTeam = async() => {
        try {
            const response = await getTeam();
            if (response.length > 0) {
                setTeamList(response);     
            }
            return () => setTeamList([])        
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchAuctionTeamByPlayerID = async(playerID) => {
        try {
            const response = await getAuctionTeamByPlayerID(playerID);
            if (response) {
                setAuctionTeam(response);     
            }      
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchTeam = async(round) => {
        try {
            const response = await getDraftTeamByRound(round);
            if (response.length > 0) {
                setAuctionPlayer(null);
                setAuctionTeam(null)
                if (response[0].PlayerID == 0) {
                    setActiveTeamCard(response[0])
                } else if (response[response.length - 1].PlayerID != 0) {
                    setActiveTeamCard(response[response.length - 1])
                } else {
                    const team = response.find(r => r.PlayerID == 0)
                    const index = response.find(r => r.Index == team.Index - 1)
                    setActiveTeamCard(index)
                }
                setTeamList(response);     
            }
            return () => setTeamList([])        
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    return (
        <>
            <PullToRefresh  onRefresh={() => window.location.reload()}>
                {!activeRound ? (
                    <NoStreamTeam teamList={teamList} />
                ) : (
                    <div className='container-fluid' style={{ overflowY: 'auto', maxHeight: '100vh' }} >
                        <div className='round-name'>
                            <h4 className='text-center text-bold margin-t30 margin-b30' style={{ color: '#f4f4f4' }}>
                                <img height="50" style={{ borderRadius: '50%', marginRight: '10px' }} src={bannerLogo}/>Golf League Auction - Category {`${activeRound.Round == 1 ? 'Gold' : activeRound.Round == 2 ? 'Silver' : activeRound.Round == 3 ? 'Bronze' : 'Other Players' }`}
                            </h4>
                        </div>
                        <TopSection auctionTeam={auctionTeam} auctionPlayer={auctionPlayer} activeRound={activeRound} teamList={teamList} activeTeamCard={activeTeamCard} />
                        <BottomSection auctionTeam={auctionTeam} auctionPlayer={auctionPlayer}/>
                        {/* {(activeRound.Round != 8 && teamList.length > 0) ? ( */}
                            {/* <TeamSection teamList={teamList} activeTeamCard={activeTeamCard} /> */}
                        {/* ) : ( */}
                        {/* <AllTeam teamList={teamList} /> */}
                        {/* <AllTeamWithCarousal teamList={teamList} /> */}
                    </div>
                )}
            </PullToRefresh>
        </>
    )
}

export default App