import React, { useState, useEffect } from 'react';
import defaultTeamImg from '../../assets/images/team.png';
import { getTeam } from '../api/teamAPI';

const NoStreamTeam = () => {
    const [teamList, setTeamList] = useState([]);

    useEffect(() => {
        fetchAllTeam();
    }, []);

    useEffect(() => {
        if (teamList.length > 0)
        console.log(teamList[0].DisplayName.length);
    }, [teamList]);

    const fetchAllTeam = async () => {
        try {
            const response = await getTeam();
            console.log(response);
            if (response.length > 0) {
                setTeamList(response)
            }
        } catch (error) {
            console.error(error);
        }
    };

    const renderTeamList = (teamList) => (
        <div className="">
            {teamList.map((t) => (
                <div
                    className="team-card"
                    key={t.ID}
                    style={{
                        display: 'inline-block',
                        marginLeft: '25px',
                    }}
                >
                    <div
                        className="jumbotron"
                        style={{
                            background: `linear-gradient(180deg, ${t.LowColor}, ${t.HighColor})`,
                            padding: '20px 0px 30px 0px',
                            borderRadius: '20px',
                            width: '275px',
                        }}
                    >
                        <div className="player">
                            <img
                                src={t.Logo ? t.Logo : defaultTeamImg}
                                className="img-responsive img-circle img-team"
                                style={{
                                    border: '3px solid #4169E1',
                                    maxWidth: '100px',
                                    maxHeight: '100px',
                                }}
                            />
                        </div>
                        <div
                            className="text-center margin-t5 margin-lr10"
                            style={{ fontSize: '160%', lineHeight: '30px' }}
                        >
                            {t.DisplayName}
                        </div>{t.DisplayName.length <= 24 && <br/>}
                        <div
                            className="text-center margin-t10"
                            style={{ fontSize: '85%' }}
                        >
                            Fund Remaining
                        </div>
                        <div
                            className="text-center"
                            style={{ fontSize: '185%', lineHeight: '20px' }}
                        >
                            Rs. {t.RemainingAmount}
                        </div>
                        <div
                            className="card-footer margin-t20"
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                            }}
                        >
                            <div
                                className="confirm"
                                style={{
                                    lineHeight: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <div
                                    className="text-center"
                                    style={{ fontSize: '90%' }}
                                >
                                    Confirmed
                                </div>
                                <div
                                    className="text-center"
                                    style={{ fontSize: '130%' }}
                                >
                                    {t.PlayerCount}
                                </div>
                                <div
                                    className="text-center"
                                    style={{
                                        lineHeight: '5px',
                                        fontSize: '75%',
                                    }}
                                >
                                    Players
                                </div>
                            </div>
                            <div
                                className="pending"
                                style={{
                                    lineHeight: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <div
                                    className="text-center"
                                    style={{ fontSize: '90%' }}
                                >
                                    Pending
                                </div>
                                <div
                                    className="text-center"
                                    style={{ fontSize: '130%' }}
                                >
                                    {16 - t.PlayerCount}
                                </div>
                                <div
                                    className="text-center"
                                    style={{
                                        lineHeight: '5px',
                                        fontSize: '75%',
                                    }}
                                >
                                    Players
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <>
        <div className="scrollable-team-container text-center">
            <div style={{ color: 'white' }}>{renderTeamList(teamList)}</div>
        </div>
        </>
    );
};

export default NoStreamTeam;
