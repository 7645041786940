import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const getTeamUrl                                      = '/api/admin/golfClub/getTeam';
const getActiveIsLiveUrl                              = '/api/admin/teamSelection/getActiveIsLive';
const getInProcessPlayerUrl                           = '/api/admin/auction/getInProcessPlayer';
const getLastProcessedPlayerUrl                       = '/api/admin/auction/getLastProcessedPlayer';
const getDraftTeamByRoundUrl                          = (round) => `/api/admin/draft/getDraftTeamByRound/${round}`;
const getAuctionTeamByPlayerIDUrl                     = (playerID) => `/api/admin/auction/getAuctionTeamByPlayerID/${playerID}`;

export const getTeam = async () => {
  
    const response = await axiosGet(
        getTeamUrl,
        { headers: "" }
    );
    return response;
};

export const getActiveIsLive = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getActiveIsLiveUrl,
        { headers: "" }
    );
    return response;
};

export const getInProcessPlayer = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getInProcessPlayerUrl,
        { headers: "" }
    );
    return response;
};

export const getLastProcessedPlayer = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getLastProcessedPlayerUrl,
        { headers: "" }
    );
    return response;
};

export const getDraftTeamByRound = async (round) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getDraftTeamByRoundUrl(round),
        { headers: "" }
    );
    return response;
};

export const getAuctionTeamByPlayerID = async (playerID) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getAuctionTeamByPlayerIDUrl(playerID),
        { headers: "" }
    );
    return response;
};
