import React, { useEffect, useState } from 'react'
import defaultUserImg from '../../assets/images/user.jpg'
import '../top-section/topSection.css'

const BottomSection = ({ auctionPlayer }) => {
    
    return (
        <>
            {auctionPlayer && (
                <>
                    <div className='col-sm-12 margin-t50'>
                        <div className='jumbotron jumbotron-custom' 
                            style={{ 
                                // backgroundColor: '#3e6fa9', 
                                background: `linear-gradient(90deg, #063554, #0b79bf)`,
                                borderRadius: '20px', border: '1px solid #898989' 
                            }}>
                            <div className='row align-items-center'>
                                {/* Image on the left on larger screens */}
                                <div className='col-md-12 text-md-start'>
                                    {auctionPlayer.PlayerID != 0 ? (
                                        <>
                                            <h4 className="fs-2 color-white margin-t0">
                                                <span>
                                                    <span className='light-text'>Age: </span>{auctionPlayer.Age ? auctionPlayer.Age : 'N/A'}
                                                </span> 
                                                <span>
                                                &nbsp;&nbsp;|&nbsp;&nbsp;<span className='light-text'>PGL Participant: </span><span className={`kt-badge kt-badge--${auctionPlayer.PglLastYearParticipant == 'Y' ? 'success' :'grey'} kt-badge--inline kt-badge--pill kt-badge-pill-width-yes` }>
                                                {auctionPlayer.PglLastYearParticipant == 'Y' ? 'Yes' : 'No' }
                                                </span>
                                                </span>
                                            </h4>
                                            <h4 className="fs-2 color-white margin-t25">
                                                <span>
                                                    <span className='light-text'>Past Team: </span>{auctionPlayer.LastYearTeamName ? auctionPlayer.LastYearTeamName : 'N/A'}
                                                </span>
                                                <span>
                                                    &nbsp;&nbsp;|&nbsp;&nbsp;<span className='light-text'>Point Score Last Year: </span>{auctionPlayer.PointScoreLastYear ? auctionPlayer.PointScoreLastYear : 'N/A'}
                                                </span>
                                            </h4>
                                            <h4 className="fs-2 color-white margin-t25">
                                            <span>
                                            <span className='light-text'>Available For All Days: </span> {auctionPlayer.IsAvailableForAllDay === 'N' ? 'No' : 'Yes'}
                                            </span>
                                            {auctionPlayer.IsAvailableForAllDay === 'N' && (
                                                <span>
                                                    &nbsp;&nbsp;|&nbsp;&nbsp;<span className='light-text'>Availability: </span>{auctionPlayer.AvailableOnlyFor}
                                                </span>
                                            )}
                                            </h4>
                                            <hr></hr>
                                            <h4 className="fs-2 color-white margin-t25"><span className='light-text'>Past Achievements: </span><br></br>{auctionPlayer.PastAchievement != "" ? auctionPlayer.PastAchievement  : 'N/A'}</h4>
                                        </>
                                    ) : (
                                        <h2 className="fs-2 color-white center-text margin-t0">TBA</h2>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default BottomSection