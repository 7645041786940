import React, { useEffect } from 'react'
import './topSection.css'
import Playercard from '../player-card/PlayerCard'
import DraftTeam from './draftTeam'
import AuctionTeam from './auctionTeam'

const TopSection = ({ auctionPlayer, activeRound, activeTeamCard, auctionTeam }) => {

    return (
        <>
            <div className='top-section row'>
                <Playercard activeRound={activeRound} auctionPlayer={auctionPlayer} activeTeamCard={activeTeamCard}/>
                {/* {activeRound.Round == 8 ? ( */}
                    <AuctionTeam auctionTeam={auctionTeam} />
                {/* ) : (
                    <DraftTeam activeTeamCard={activeTeamCard} />
                )} */}
            </div>


        </>
    )
}

export default TopSection