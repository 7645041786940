import React, { useEffect } from 'react'
import defaultTeamImg from '../../assets/images/team.png'
import './topSection.css'

const AuctionTeam = ({ auctionTeam }) => {

    return (
        <>
            {auctionTeam ? (
                <div className='col-sm-6'>
                    <div className='jumbotron jumbotron-custom padding-b45 padding-t35' 
                        style={{ 
                            // backgroundColor: '#ef7f0f', 
                            background: `linear-gradient(90deg, ${auctionTeam.TeamID != 0 ? auctionTeam.LowColor : '#fdb302'}, ${auctionTeam.TeamID != 0 ? auctionTeam.HighColor : '#ef7f11'})`,
                            borderRadius: '20px', border: '1px solid #898989' }}>
                        <div className='row align-items-center'>
                            {/* Image on the left on larger screens */}
                            <div className='col-md-12 col-lg-4 d-flex justify-content-center justify-content-md-end'>
                                <div className='player'>
                                    <img
                                        src={auctionTeam.Logo ? auctionTeam.Logo : defaultTeamImg}
                                        className='img-responsive img-circle img-user'
                                        style={{ border: '3px solid #4169E1' }}
                                    />
                                </div>
                            </div>

                            {/* Text on the right on larger screens */}
                            <div className='col-md-12 col-lg-8 text-md-start'>
                                {auctionTeam.TeamID != 0 ? (
                                    <>
                                        <h2 className="fs-2 color-white">{auctionTeam.TeamName}</h2>
                                        <h3 className='fs-2 color-white'>Rs.{auctionTeam.Amount}</h3>
                                        <h4 className="fs-4 color-white">Winning Bid</h4>
                                    </>
                                ) : (
                                        <h2 className="fs-2 color-white center-text margin-t0">Unsold</h2>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='col-sm-6'>
                    <div className='jumbotron jumbotron-custom' 
                        style={{ 
                            background: `linear-gradient(90deg, #fdb302, #ef7f11)`,
                            borderRadius: '20px', border: '1px solid #898989' 
                        }}>
                        <div className='row align-items-center'>
                            {/* Image on the left on larger screens */}
                            <div className='col-md-12 col-lg-4 d-flex justify-content-center justify-content-md-end'>
                                <div className='player'>
                                    <img
                                        src={defaultTeamImg}
                                        className='img-responsive img-circle img-user'
                                        style={{ border: '3px solid #4169E1' }}
                                        alt="Player"
                                    />
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-8 text-md-start'>
                                <h2 className="fs-2 color-white center-text margin-t0">TBA</h2>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default AuctionTeam