import React, { useState, useEffect, useRef } from 'react';
import defaultTeamImg from '../../assets/images/team.png';
import './TeamSection.css';
import { getDraftTeamByRound } from '../api/teamAPI';

const TeamSection = ({ teamList, activeTeamCard }) => {
    const scrollRef = useRef(null);
    const [isDragging,  setIsDragging]  = useState(false);
    const [startX,      setStartX]      = useState(0);
    const [scrollLeft,  setScrollLeft]  = useState(0);

    const getShiftedTeamList = () => {
        if (activeTeamCard && activeTeamCard.TeamID) {
            const activeIndex = teamList.findIndex(team => team.TeamID === activeTeamCard.TeamID);
            if (activeIndex === -1) return teamList;
    
            return [...teamList.slice(activeIndex), ...teamList.slice(0, activeIndex)];
        }
        return teamList;
    };

    const shiftedTeamList = getShiftedTeamList();

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const x = e.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX) * 1.5;
        scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    return (
        <>
            <div className='team-section row margin-t30 margin-b60' style={{ color: 'white' }}>
                <div
                    className="scrollable-team-container"
                    ref={scrollRef}
                    onMouseDown={handleMouseDown}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                    style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '10px 0', cursor: isDragging ? 'grabbing' : 'grab' }}
                >
                    {shiftedTeamList.length > 0 && (
                        shiftedTeamList.map((t, i) => {
                            {/* const isActive = t.TeamID === activeTeamCard.TeamID; */}
                            const isActive = activeTeamCard?.TeamID && t.TeamID === activeTeamCard.TeamID;
                            return (
                                <div
                                    className='team-card'
                                    key={t.ID || i}
                                    style={{
                                        display: 'inline-block',
                                        marginLeft: '25px'
                                    }}
                                >
                                    <div
                                        className='jumbotron'
                                        style={{
                                            background: `linear-gradient(180deg, ${t.LowColor}, ${t.HighColor})`,
                                            padding: '20px 0px 30px 0px',
                                            borderRadius: '20px',
                                            width: '200px',
                                            boxShadow: isActive ? '0px 0px 15px 5px white' : 'none',
                                            border: isActive ? '2px solid white' : '1px solid #898989',
                                            overflow: 'hidden',
                                            boxSizing: 'border-box'
                                        }}
                                    >
                                        <div className='player'>
                                            <img
                                                src={t.Logo ? t.Logo : defaultTeamImg}
                                                className='img-responsive img-circle img-team'
                                                style={{ border: '3px solid #4169E1', maxWidth: '100px', maxHeight: '100px' }}
                                            />
                                        </div>
                                        <div className='text-center margin-t5' style={{ fontSize: '160%' }}>{t.DisplayName}</div>
                                        <div className='text-center margin-t10' style={{ fontSize: '85%' }}>Fund Remaining</div>
                                        <div className='text-center' style={{ fontSize: '185%', lineHeight: '20px' }}>Rs. {t.RemainingAmount}</div>
                                        <div className='card-footer margin-t20' style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <div className='confirm' style={{ lineHeight: '20px', display: 'flex', flexDirection: 'column' }}>
                                                <div className='text-center' style={{ fontSize: '90%' }}>Confirmed</div>
                                                <div className='text-center' style={{ fontSize: '130%' }}>{t.PlayerCount}</div>
                                                <div className='text-center' style={{ lineHeight: '5px', fontSize: '75%' }}>Players</div>
                                            </div>
                                            <div className='pending' style={{ lineHeight: '20px', display: 'flex', flexDirection: 'column' }}>
                                                <div className='text-center' style={{ fontSize: '90%' }}>Pending</div>
                                                <div className='text-center' style={{ fontSize: '130%' }}>{18 - t.PlayerCount}</div>
                                                <div className='text-center' style={{ lineHeight: '5px', fontSize: '75%' }}>Players</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
            </div>
        </>
    );
};

export default TeamSection;